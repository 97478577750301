import React from 'react';

import MenuItem from './MenuItem';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faHouse } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot, faPhone, faFax, faEnvelope, faUser} from '@fortawesome/free-solid-svg-icons'

const Kontaktdaten = (props) => {
    return (
        <React.Fragment>
            <div className="kontaktdaten">
                <Row>
                                <Col className="icon" xs={1}>
                                    <FontAwesomeIcon icon={faLocationDot} />
                                </Col>
                                <Col className="text" xs={11}>
                                    Weidenauer Str. 174<br />
                                    57076 Siegen
                                </Col>
                            </Row>
                            <Row>
                                <Col className="icon" xs={1}>
                                    <FontAwesomeIcon icon={faPhone} />
                                </Col>
                                <Col className="text" xs={11}>
                                <a href="tel:+49271 42033">0271 42033</a>
                                </Col>
                            </Row>
                            {/*
                            <Row>
                                <Col className="icon" xs={1}>
                                    <FontAwesomeIcon icon={faFax} />
                                </Col>
                                <Col className="text" xs={11}>
                                    0271 43865
                                </Col>
                            </Row>
                            */}
                            <Row>
                                <Col className="icon" xs={1}>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </Col>
                                <Col className="text" xs={11}>
                                    <a href="mailto:abc@meineplusapotheke.de">abc@meineplusapotheke.de</a>
                                </Col>
                            </Row>
                            {/*
                            <Row>
                                <Col className="icon" xs={1}>
                                    <FontAwesomeIcon icon={faUser} />
                                </Col>
                                <Col className="text" xs={11}>
                                    Kathleen Benevolo
                                </Col>
                            </Row>
                            */}
            </div>            
            
        </React.Fragment>
        
    )
}

export default Kontaktdaten;